import Script from "next/script";

export default function GsiOnetapWidget({ nonce }) {
  return (
    <>
      <div
        className="container"
        id="g_id_onload"
        data-testid="g_id_onload"
        data-client_id={process.env.CLIENT_ID}
        data-prompt_parent_id="g_id_onload"
        data-cancel_on_tap_outside="false"
        data-itp_support="true"
        data-callback="handleCredentialResponse"
        data-context="signup"
        data-use_fedcm_for_prompt="true"
      >
        <div
          id="credential_picker_container"
          data-testid="credential_picker_container"
        ></div>
        <style global jsx>{`
          #g_id_onload {
            position: relative;
            z-index: 9;
            text-align: end;
            top: 85px;
            height: 0px;
          }
          #credential_picker_container {
            position: relative;
            z-index: 10 !important;
            top: 0px;
            left: 0px;
            margin-left: auto;
            width: 390px !important;
          }
        `}</style>
      </div>
      <Script
        id="gsi-script"
        data-testid="gsi-script"
        strategy="afterInteractive"
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `
      function handleCredentialResponse(token) {
        let credential = token.credential; // access token
        async function getData() {
          const url = "/api/googleonetap";
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                accessToken: credential,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
            });
            const signupResponse = await response.json();
            if(!signupResponse?.success || !signupResponse?.data?.continueURL) {
              console.error("Signup failure")
            }
            window.location.href = signupResponse.data.continueURL;
          } catch (error) {
            console.error(error.message);
          }
        }
        getData(); 
      }
    `,
        }}
      />
    </>
  );
}
